<template>
  <div :class="`absolute w-screen h-screen flex text-yellow-light bg-opacity-90 justify-start bg-cover items-center bg-center bg-buildings`">
    <div class="absolute w-screen h-screen flex  flex-col align-center text-yellow-light bg-midnight-blue bg-opacity-95 justify-center items-center">
    <div v-if="courses || win" :class="[!win ? 'text-center justify-center' : 'justify-around','h-full min-h-screen site-content flex  items-center w-full text-left']">
      <div>
        <img v-if="win" :src="illus" alt="" class="rounded-md right-20 w-10/12 relative -rotate-3 " style="border-width: 16px;"  >
      </div>
      <div class="w-1/2">
      <div class=" pr-10">
        <small class="grand-title text-yellow-light">{{win ? $t.enquete.text_win : $t.enquete.text_loose}}</small>
        <h2 class="huge-text-bold ">{{win ? $t.enquete.text_win_desc : $t.enquete.text_loose_desc}}</h2>
        <div class="grand-text mt-10 mb-10">{{win ? course.metas['text_outro'] : $t.enquete.text_loose_instruction}} </div>
        <div :class="`flex ${!win ? 'justify-center' : ''}`">
          <router-link :to="'/doc#'+currentModule.substring(0,3)"  v-if="win"><ShadowButtonResponsive icon="docu-midnight" class="pl-2 pr-4" theme="light-yellow-midnight-blue">{{$t.buttons.bouton_documentation}}</ShadowButtonResponsive></router-link>
          <ShadowButtonResponsive :left-align="true" class="pl-2" :theme="!win ? 'light-yellow-midnight-blue' : 'midnight-blue-light-yellow'" @click="nextStep()">{{$t.buttons.bouton_continuer}}</ShadowButtonResponsive>
        </div>
      </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ShadowButton from "../../components/ShadowButton";
import {mapState} from "vuex";
import { getMediaFromId } from "../../utils/apollo-parser";
import { setModuleDone, getFinishedAllCourseDate } from '@/composables/user_log';
import ShadowButtonResponsive from "../../components/ShadowButtonResponsive";


export default {
  emits: ["nextstep"],
  components: {
    ShadowButtonResponsive,
  },
  async mounted(){
    const mediaQueryResult = await getMediaFromId( this.course.metas['cover'])
    this.illus =  this.mediaserverurl + mediaQueryResult.data.item.path
  },
  data() {
    return {
      mediaserverurl: process.env.VUE_APP_MEDIA_URL,
      illus: ''
    }
  },
  props: {
    title:{
      type: String,
      default: 'UN TITRE'
    },
    win: {
      type:Boolean,
      default: true
    },
    duration: {
      type: [String, Number],
      default: 10
    },
    courses: {
      type: Object,
    },
    moduleId:{
      type: String,
      default: undefined
    },
    illusId: {
      type:String,
      default: ','
    }
  },
  computed:{
    course() {
      if(!this.courses || this.courses.length <= 0 || !this.currentModule)
        return null

      return this.courses[0].modules.find(m => m.id ===this.currentModule)
    },
    ...mapState({
      currentSequence: state => state.gamestatus.currentSequence,
      currentModule: state => state.gamestatus.currentModule,
      currentTheme: state => {
        return state.currentTheme
      },

      customBg() {
        return {
          'background-image':`url(${this.enquete.thumbAndBgPic})`,
        }
      },
      enquete: state => state.enquete.enquete
    })
  },
  methods: {

    async nextStep(){
      if(!this.win){
        await this.$router.push({name: 'enquete/EnqueteWrapper', params: {noIntro: 'rattrapage'}})
      }else{
        const hadFinished = !!getFinishedAllCourseDate()

        await setModuleDone(this.currentModule, true)

        const route = { name: 'enquete/EnqueteWrapper', params: {noIntro: 'select'}}

        // Redirect user to congrats screen if he just finished all course
        if (!hadFinished && getFinishedAllCourseDate()) {
          route.name = 'Congrats'
          route.params = {}
        }
        
        await this.$router.push(route)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blue-quest {
 .rounded{
   @apply bg-blue-quest
 }
}
.green-quest {
  .rounded{
    @apply bg-green-quest
  }
}
.yellow-quest {
  .rounded{
    @apply bg-yellow-quest
  }
}
.pink-quest {
  .rounded{
    @apply bg-pink-quest
  }
}
</style>
